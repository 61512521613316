import React from "react"
import { rhythm, scale } from "../utils/typography"

class TagList extends React.Component {
  renderTagItem = tag => (
    <li
      key={tag}
      style={{
        display: `inline-block`,
        background: `#808080`,
        color: `#202020`,
        padding: `0 8px`,
        marginRight: "4px",
        marginTop: "4px",
        fontSize: `13px`,
        fontWeight: `400`,
        borderRadius: `5px`,
      }}
    >
      {tag}
    </li>
  )

  render() {
    const { tags } = this.props

    if (!tags) {
      return null
    }

    return (
      <ul
        style={{
          ...scale(-1 / 5),
          listStyle: `none`,
          whiteSpace: `nowrap`,
          display: `block`,
          marginBottom: rhythm(1),
          marginTop: rhythm(-1),
        }}
      >
        {tags.map(t => this.renderTagItem(t))}
      </ul>
    )
  }
}

export default TagList
